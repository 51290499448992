import React from 'react'
import { graphql } from 'gatsby'

import Layout from  '../components/common/layout'
import Seo from "../components/common/seo"

export const query = graphql`
  query (
    $url:String!
  ) {
    bigCommercePages(
      url: {
        eq:$url
      }
    ) {
      id
      url
      name
      meta_title
      meta_description
      type
      body
      email
      contact_fields
    }
  }
`

const Page = (props) => {
  return (    
    <Layout>
      <Seo
        title={props.data.bigCommercePages.meta_title}
        description={props.data.bigCommercePages.meta_description}
        />
      <h1>{props.data.bigCommercePages.name}</h1>
      <p>type: <strong>{props.data.bigCommercePages.type}</strong></p>
      <p>email: <strong>{props.data.bigCommercePages.email}</strong></p>
      <p>contact_fields: <strong>{props.data.bigCommercePages.contact_fields}</strong></p>
      <article dangerouslySetInnerHTML={{ __html: props.data.bigCommercePages.body }}></article>
    </Layout>
  )
}

export default Page;